<template>
  <div class="error-404" :style="{ background: bgColor, color: fontColor }" v-if="!isLoading">
    <!-- afficher des trucs -->
    <img class="error-404__logo" :src="info.logo" alt="logo" v-if="info.logo" />
    <img :src="require('@/assets/images/logo-light.svg')" alt="logo" class="error-404__logo" v-else />
    <div class="error-404__not-found">ERROR 404<br />Page Introuvable</div>
    <div class="error-404__maybe-interested" v-html="info.title"></div>
    <div class="error-404__description" v-html="info.description"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
      isLoading: true,
    }
  },
  computed: {
    bgColor() {
      return this.info.theme?.backgroundColor
    },
    fontColor() {
      return this.info.theme?.fontColor
    },
  },
  created() {
    // call /fallback
    fetch('/fallback').then(res => {
      res.json().then(res => {
        this.info = res
        this.isLoading = false
      })
    })
  },
}
</script>

<style lang="scss" scoped>
.error-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0c0058;
  padding: 24px;
  min-height: 100%;
  color: white;
}
.error-404__logo {
  width: 400px;
  height: auto;
}
.error-404__not-found {
  margin: 48px 0;
  text-align: center;
  font-size: 48px;
}
.error-404__maybe-interested,
.error-404__description {
  text-align: center;
  font-size: 24px;
}
</style>

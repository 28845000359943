import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Fallback from '../views/404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/404',
    name: '404',
    component: Fallback,
  },
  {
    path: '/*',
    name: 'Home',
    component: Home,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router

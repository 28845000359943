<template>
  <div class="home"></div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  created() {
    fetch('/redirect/').then(res => {
      res.json().then(redirectObject => {
        if (redirectObject.r) window.location.href = redirectObject.r
      })
    })
  },
}
</script>
